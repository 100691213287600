.cookie {

	//cookie bar
	&__bar {
		box-sizing: border-box;
		font-size: 12px;
		font-family: inherit;
		@include fixed(left 0);
		width: 100%;
		line-height: line-height(16, 12);
		@include z-index('blackhole');

		*,
		*::before,
		*::after {
			box-sizing: inherit;
		}

		a {
			color: inherit;
			text-decoration: none;
			font-weight: 700
		}

		&-text {
			flex-basis: 100%;
			@include margin($bottom: 10px);

			@include bp('extra-large') {
				flex-basis: 38%;
				@include margin($right: auto, $bottom: 0);
			}

			@include bp('wide') {
				flex-basis: 45%;
			}

			@include bp('extra-wide') {
				flex-basis: 53%;
			}
		}

		&-link {
			@include margin($left: 5px)
		}


		&.text {
			&--white {
				color: $white
			}

			&--black {
				color: $black
			}
		}

		&.bottom {
			bottom: 0
		}

		&.top {
			top: 0
		}

		.cookie-btn {
			cursor: pointer;
			text-transform: uppercase;
			border-width: 2px;
			border-style: solid;
			@include padding($left: 35px, $right: 35px, $top:10px, $bottom:10px);
			flex-basis: 100%;
			text-align: center;
			@include margin($left: 0);

			@include bp('medium') {
				@include margin($left: 15px);
				flex-basis: auto
			}

			&.btn {
				&--settings {
					order: 2;
					@include margin($left: auto);

					@include bp('medium') {
						order: 0
					}

					@include bp('extra-large') {
						@include margin($left: 0);
					}
				}

				&--reject {
					order: 1;

					@include bp('medium') {
						order: 0
					}
				}

				&--allow,
				&--reject {
					@include margin($bottom: 10px);

					@include bp('medium') {
						@include margin($bottom: 0)
					}
				}
			}
		}
	}

	&__container {
		@extend %flex-center-v;
		@include margin($short: 0);
		@include padding($left: 25px, $right: 25px, $top:15px, $bottom:15px);
	}

	//cookie mmodal
	&__modal {
		display: none;

		*,
		*::before,
		*::after {
			box-sizing: inherit;
		}

		&.active {
			box-sizing: border-box;
			@extend %flex-center;
			@include fixed(top 0 left 0);
			@include size(100vw, 100vh);
			background-color: fade-out($black, .25);
			@include z-index('blackhole');
			font-family: inherit;
		}

		&-container {
			@extend %flex-column-center-h;
			flex-wrap: nowrap;
			@include size(100%);
			max-width: 600px;
			max-height: 450px;
			background-color: $white;
			overflow: hidden
		}

		&-header {
			@extend %flex-center-v;
			@include size(100%, 80px);
			@include relative;
		}

		&-close {
			@include absolute(right 20px top 20px);
			font-family: $trebs;
			cursor: pointer;
		}

		&-logo {
			@include size(150px, 80px);
			@include relative;
			@extend %flex-center-v;

			&::after {

				@include bp('medium') {
					content: '';
					background-color: $white;
					@include size(1px, 55px);
					@include _('position-center-v');
					right: 0;
				}
			}

			img {
				@include size(100%, auto);
				@include padding($short: 0 15px)
			}
		}

		&-title {
			display: none;

			@include bp('medium') {
				text-transform: uppercase;
				color: $white;
				@include padding($left: 40px);
				font-size: 20px;
				display: block;
			}
		}

		&-body {
			display: flex;
			flex-grow: 1;
			width: 100%; // fix Chrome Safari
			background: $white
		}

		&-tabs {
			flex-grow: 1;
			@extend %flex-wrap;
			font-family: $arial;

			>ul {
				@extend %flex-wrap;
				@include margin($short: 0);
				@include padding($short: 0);
				list-style: none;
				background: $gray-light;
				font-size: 12px;
				flex-basis: 100%;

				@include bp('medium') {
					display: block;
					max-width: 150px;
				}

				li {
					@include padding($short: 10px);
					border-bottom: 1px solid $gray-darker;
					color: $gray-dark;
					background-color: $gray-lighter;
					flex-basis: 100%;

					@include bp('medium') {
						@include padding($left: 10px, $right:30px, $top:25px, $bottom:25px);
						flex-basis: auto;
					}


					&::before,
					&::after {
						background: none;
						content: '';
					}

					&:not(.cookie-current) {
						cursor: pointer;
					}

					&.cookie-current {
						color: $black;
						background-color: $gray;
						@include relative;

						&::after {
							display: none;

							@include bp('medium') {
								content: '';
								width: 0;
								height: 0;
								border-bottom: 7px solid transparent;
								border-top: 7px solid transparent;
								border-left: 7px solid $gray;
								font-size: 0;
								line-height: 0;
								right: -7px;
								@include _('position-center-v');
							}
						}
					}
				}
			}

			&-content {
				display: none;
				overflow: hidden;

				&.cookie-current {
					@extend %flex-column;
					@include padding($short: 20px);
					max-height: 220px;
					color: $black;

					@include bp('medium') {
						flex: 1;
						@include padding($left: 40px, $right: 40px, $top:20px, $bottom:20px);
						max-height: 330px;
					}
				}

				.cookie__modal-title {
					color: $black;
					@include padding($left: 0);
					@include margin($bottom: 10px);
					font-size: 16px;
					display: block;
					text-transform: uppercase;

					@include bp('medium') {
						display: none;
					}
				}

				&-title {
					font-size: 15px;
					font-weight: 700;
					text-transform: uppercase;
					@include margin($bottom: 25px);
					@extend %flex-center-v;

					>span {
						flex-basis: 70%
					}

					label {
						@include margin($left: auto);
						text-transform: none;
						color: $gray;
						font-weight: 700;
						font-size: 14px;
						line-height: line-height(14, 14);

						&[for] {
							@include relative;
							cursor: pointer;

							&::before {
								content: '';
								@include size(10px);
								@include box-shadow(inset 0 0 0 1px $white);
								border: 1px solid $gray;
								@include absolute(left -25px top 2px);
								@include border-radius(inherit);
							}

							span {
								background-color: $white;
								display: block
							}
						}
					}

					input {
						@extend %hide-element;

						&:checked {
							+label {
								&::before {
									background-color: inherit;
									border-width: 1px;
									border-style: solid;
									border-color: inherit
								}
							}
						}
					}
				}

				&-text {
					font-weight: 400;
					font-size: 12px;
					line-height: line-height(16, 12);
					max-height: 90%;
					overflow-y: auto;
					overflow-x: hidden;

					p,
					ul {
						@include margin($top:0);
					}
				}

				&-list {
					@include margin($top: 20px);
					font-weight: 700;

					span {
						font-size: 14px;
						text-transform: uppercase;
						display: block;
						@include margin($bottom: 10px);
					}

					li {
						list-style-type: disc;
						@include margin($left: 10px);
					}
				}
			}
		}

		&-footer {
			@include size(100%, 40px);
			@extend %flex-center-v;
			justify-content: flex-end;
			@include padding($right: 20px)
		}

		&-btn {
			text-transform: uppercase;
			background: $white;
			font-size: 10px;
			font-weight: 700;
			@include padding($left: 15px, $right: 15px, $top:5px, $bottom:5px);
			cursor: pointer;
			line-height: line-height(15, 10);
			background-clip: padding-box;
			text-decoration: none;

			&-close {
				background-clip: padding-box;
				background-color: $white;
				line-height: line-height(10, 10);
				@include size(20px);
				@extend %flex-center;
				text-decoration: none;
			}
		}
	}
}