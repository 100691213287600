//base-font
$base-font: 62.5%;

//breakpoint vars
$extra-small: 22.5em; //360px
$small: 36em;  //576px
$medium: 48em;  //768px
$large: 64em; //1024px
$extra-large: 75em; //1200px
$wide: 90em; //1440px
$extra-wide: 100em; //1600px

//breakpoints
$breakpoints: (
    'extra-small': $extra-small,
    'small': $small,
    'medium': $medium,
    'large': $large,
    'extra-large': $extra-large,
    'wide': $wide,
    'extra-wide': $extra-wide
);

// Caching current breakpoint
// Not meant to be manually edited
$default-breakpoint: root;
$current-breakpoint: $default-breakpoint;

// Caching existing placeholders
// Not meant to be manually edited
$placeholders: ();

//colors
$white: #fff;
$black: #000;
$gray: #dedede;
$gray-light: #f9f9f9;
$gray-lighter: #ededed;
$gray-dark: #bfbfbf;
$gray-darker: #b4b4b4;

//fonts
$trebs: 'Trebuchet MS', sans-serif;
$arial:  Arial, Helvetica, sans-serif;

//z-index
$z-index: (
    'pothole' : -1,
    'basement' :0,
    'sidewalk': 1,
    'bungalow': 40,
    'bungalow-chimney': 50,
    'house': 60,
    'tower': 70,
    'skyscrapper': 80,
    'sky': 90,
	'satellite' : 100,
	'blackhole' : 2147483647
);
// //breakpoints
// $breakpoints: ('mobile-xs':576px, 'mobile': 767px, 'tablet': 992px, 'desktop': 1024px);